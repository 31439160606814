new Vue({
  el: '#listings',
  data() {
    return {
      selected: 'item-0',
    }
  },
  methods: {
    toggleSelected(item) {
      this.selected = item;
    }
  }
});